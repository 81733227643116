import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <p>
        © All rights reserved - {year} © Jarno De Paemeleere for the pictures
      </p>
    </footer>
  );
};

export default Footer;
